import React, { useEffect, useState, useRef } from 'react'
import FocusTrap from 'focus-trap-react'
import { m } from 'framer-motion'
import cx from 'classnames'

import { getSanityClient } from '@lib/sanity'
import { isBrowser } from '@lib/helpers'
import CustomLink from '@components/link'
import Icon from './icon'
import Photo from './photo'
import { queries } from '@data'

const Story = () => {
  const [isStoryOpen, toggleStory] = useState(false)
  const [backPath, setBackPath] = useState()
  const [story, setStory] = useState()
  const [stories, setStories] = useState()
  const [photos, setPhotos] = useState({})
  const containerRef = useRef()

  const [interval, setIntervalRef] = useState(null)
  const intervalRef = useRef(interval)
  intervalRef.current = interval
  const [currentIndex, setCurrentIndex] = useState(null)
  const nextRef = useRef()
  const prevRef = useRef()

  const [isLoading, setIsLoading] = useState(true)
  const [lastPaused, setLastPaused] = useState(null)
  const [isPaused, setIsPaused] = useState(false)
  const isPausedRef = useRef(isPaused)
  isPausedRef.current = isPaused

  const hasTouch = isBrowser ? 'ontouchstart' in window : false

  const currentSlide = () => {
    if (isLoading || !story?.slides) return null
    return story.slides[currentIndex]
  }

  const preloadNext = () => {
    let next = story.slides[currentIndex + 1]?.photo

    // Load cover for next story
    if (currentIndex >= story.slides.length - 1) next = getNextStory().cover
    setPhotos((p) => ({ ...p, next }))
  }

  const onPathChange = (e) => {
    clearInterval(intervalRef.current)
    const paths = location.pathname.substring(1).split('/')
    if (paths[0] === 'explore' && paths.length === 3) {
      // Set back path for closing story
      if (e.detail?.back) setBackPath(e.detail.back)

      // Reset
      toggleStory(true)
      setCurrentIndex(null)
      setIsLoading(true)

      // Load new story
      getSanityClient()
        .fetch(
          `
        *[_type == "story" && slug.current == "${paths[paths.length - 1]}"][0]{
          ${queries.page},
          title,
          slides[]{
            photo{
              ${queries.imageMeta}
            },
            products[]->{
              ${queries.page}
            }
          }
        }
        `
        )
        .then((data) => {
          setIsLoading(false)
          setStory(data)
        })
    } else {
      onClose(true)
    }
  }

  const onClose = (isPopstate = false) => {
    if (backPath && !isPopstate) window.history.pushState(null, null, backPath)

    // Clear back path for future
    setBackPath(null)

    // Clear
    clearInterval(intervalRef.current)

    // Close
    toggleStory(false)
  }

  const handleKeyDown = (e) => {
    if (e.which === 27) onClose()
  }

  const hasProducts = () => currentSlide()?.products?.length > 0

  const onPause = () => {
    setLastPaused(Date.now())
    setIsPaused(true)
    window.addEventListener(hasTouch ? 'touchend' : 'mouseup', onResume)
  }

  const onResume = (e) => {
    setLastPaused(null)
    setIsPaused(false)
    window.removeEventListener(hasTouch ? 'touchend' : 'mouseup', onResume)
  }

  const getNextStory = () => {
    if (!stories || stories.length < 2) return false
    const next = stories.map((s) => s.slug).indexOf(story?.slug) + 1
    if (next < stories.length) return stories[next]
    return false
  }

  const getPrevStory = () => {
    if (!stories || stories.length < 2) return false
    const previous = stories.map((s) => s.slug).indexOf(story?.slug) - 1
    if (previous >= 0) return stories[previous]
    return false
  }

  const gotoSlide = (index) => {
    // Don't switch story, if holding in to pause
    if (!lastPaused || Date.now() - lastPaused < 500) {
      if (index >= 0 && index < story?.slides?.length) {
        // Show next slide
        setCurrentIndex(index)
      } else {
        // Index
        let btn = nextRef.current
        if (index < 0) btn = prevRef.current
        if (btn) {
          // Go to next story
          btn.click()
        } else {
          // No more stories
          // Pause, clear and close
          onClose()
        }
      }
    }
  }

  const updateProgressBar = (pct) => {
    Array.from(
      document.getElementsByClassName('story--slide-indicator--inner')
    ).forEach(
      (elem, i) =>
        (elem.style.width =
          currentIndex === i ? `${pct * 100}%` : currentIndex < i ? 0 : '100%')
    )
  }

  const getProductsLabel = () => {
    const ps = currentSlide()?.products?.length
    return ps < 10 ? `0${ps}` : ps
  }

  const handleAnimationComplete = (v) => {
    if (v === 'hide') setPhotos(null)
  }

  useEffect(() => {
    if (typeof currentIndex === 'number') {
      // Reset timer
      updateProgressBar(0)
      let startTime = Date.now()
      let lastIntervalDuration = 0

      setIntervalRef(() =>
        setInterval(() => {
          // Pause when holding down
          if (isPausedRef.current)
            startTime += Date.now() - lastIntervalDuration
          lastIntervalDuration = Date.now()

          // Show image or text for 5 seconds
          let pct = (Date.now() - startTime) / 5000
          updateProgressBar(Math.min(1, pct))
          if (pct >= 1) {
            // Slide done
            gotoSlide(currentIndex + 1)
            clearInterval(intervalRef.current)
          }
        }, 50)
      )
      setPhotos((p) => ({ ...p, current: currentSlide()?.photo }))
    }

    return () => clearInterval(intervalRef.current)
  }, [currentIndex])

  useEffect(() => {
    if (isBrowser)
      document.documentElement.classList.toggle('prevent-scroll', isStoryOpen)

    // Fetch stories for next/prev buttons
    if (!stories)
      getSanityClient()
        .fetch(
          `*[_type == "story"]{
            "cover": slides[0].photo {
              ${queries.imageMeta}
            },
            ${queries.page},
          }`
        )
        .then(setStories)
  }, [isStoryOpen])

  useEffect(() => {
    if (story) gotoSlide(0)
  }, [story])

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener('pushstate', onPathChange)
      window.addEventListener('popstate', onPathChange)

      containerRef.current.addEventListener(
        hasTouch ? 'touchstart' : 'mousedown',
        onPause
      )
    }
  }, [])

  return (
    <>
      <FocusTrap
        active={isStoryOpen}
        focusTrapOptions={{ allowOutsideClick: true }}
      >
        <m.div
          initial="hide"
          animate={isStoryOpen ? 'show' : 'hide'}
          variants={{
            show: {
              opacity: 1,
            },
            hide: {
              opacity: 0,
            },
          }}
          transition={{ duration: 0.6, ease: [0.16, 1, 0.3, 1] }}
          onKeyDown={handleKeyDown}
          onAnimationComplete={handleAnimationComplete}
          className={cx('story', {
            'is-active': isStoryOpen,
          })}
        >
          <div className="story--slides-container" ref={containerRef}>
            <div className="story--hud">
              {(story?.slides || []).map((slide, i) => (
                <div key={i} className="story--slide-indicator">
                  <div className="story--slide-indicator--inner" />
                </div>
              ))}
              <button className="story--close" onClick={() => onClose()}>
                <Icon name="Cross" color="white" className="w-3 h-3" />
              </button>
            </div>
            <div className="story--navigation">
              {getPrevStory() && (
                <CustomLink
                  link={{ page: getPrevStory() }}
                  className="story--navigation--prev"
                  ref={prevRef}
                >
                  <Icon name="ChevronLeft" color="white" className="w-5 h-5" />
                  Previous{' '}
                </CustomLink>
              )}
              {getNextStory() && (
                <CustomLink
                  link={{ page: getNextStory() }}
                  className="story--navigation--next"
                  ref={nextRef}
                >
                  Next{' '}
                  <Icon name="ChevronRight" color="white" className="w-5 h-5" />
                </CustomLink>
              )}
              <button
                className="left-0"
                onClick={() => gotoSlide(currentIndex - 1)}
              />
              <button
                className="right-0"
                onClick={() => gotoSlide(currentIndex + 1)}
              />
            </div>
            {currentIndex === 0 && (
              <div className="story--title">{story?.title}</div>
            )}
            <div className="story--content">
              {hasProducts() && (
                <div className="story--products">
                  <CustomLink
                    link={{
                      page: story,
                      urlParams: `?slide=${currentIndex + 1}`,
                    }}
                    shallow={false}
                    onClick={() => onClose()}
                  >
                    Products ({getProductsLabel()})
                  </CustomLink>
                </div>
              )}
              <Photo
                photo={photos?.current}
                layout="story"
                onLoad={preloadNext}
                hasPlaceholder={false}
                retina
              />
              <Photo
                photo={photos?.next}
                layout="story"
                className="is-preloader"
                hasPlaceholder={false}
                retina
              />
            </div>
          </div>
        </m.div>
      </FocusTrap>

      <div
        className={cx('backdrop', {
          'is-active': isStoryOpen,
        })}
        onClick={() => onClose()}
      />
    </>
  )
}

export default Story
